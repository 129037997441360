<template>
    <div class="flex h-full flex-col">
        <div class="neo-form-builder__header w-full bg-white p-2 flex justify-between items-center shadow-md sticky top-0 z-10">
            <div class="logos flex">
                <img :src="additionalLogo ? additionalLogo : additionalIcon" class="object-contain mr-4 md:mr-6 w-24 md:w-24 h-auto" />
                <!-- <img :src="additionalIcon" class="object-contain w-24 md:w-24 h-auto" /> -->
            </div>
            <div class="border-l p-2 border-gray-300">
                <div class="pl-4 flex flex-col pr-3">
                    <span class="text-gray-500 text-xs">Welcome</span>
                    <span>{{userInfo?.user?.name && getUserName(userInfo?.user?.name)}}</span>
                    <!-- <span> {{ `${userInfo.first_name ?? ""} ${userInfo.last_name ?? ""}` }}</span> -->
                </div>
            </div>
        </div>
        <NeoFormBuilder v-if="getFormData" :data="getFormData" @submit="saveForm" :saveSectionMethod="saveSection" :isAddReadOnly="userInfo.read_access_only" :userData="getUserData"/>
        <div class="w-full h-full flex items-center justify-center bg-blue-50" v-else-if="!getFormError && getFormData == null">
            <loader />
        </div>
        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="isSubmitted || getFormError">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 formSubmitModal">
                    <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div class="bg-white rounded-lg">
                            <div class="p-6 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto mb-4 text-gray-400 w-14 h-14">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                <h3 class="mb-5 text-lg font-normal text-gray-500">Form has been submitted!</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../axios";

import { mapGetters } from "vuex";
const NeoFormBuilder = () => import("@shared/components/form-builder");
const Loader = () => import("@shared/components/loader");

import { FormsPreviewServ } from "../services";

import { FETCH_FORM_DATA } from "../store";

export default {
    name: "Form-Views",
    data() {
        return {
            isSubmitted: false,
            additionalLogo: null,
            additionalIcon: null,
            userInfo:"",
            form_type: ""
        };
    },
    components: { Loader, NeoFormBuilder },
    computed: {
        ...mapGetters(["getFormData", "getFormError"]),
        getFormType() {
            return this.$route.params.type;
        },
        getUserData() {
            let user;
            if(this.form_type == "referee") {
                user = this.getFormData?.candidate ? this.getFormData?.candidate : null
            } else {
                user = this.getFormData?.user ? this.getFormData?.user : null;
            }
            return user
        }
    },
    async mounted() {
        let formType = this.getFormType === "case_references" ? "referee" : this.getFormType;
        this.form_type = formType;
        const userData = await FormsPreviewServ.fetchForms({ type: formType });
        this.userInfo = userData
        await this.fetchUserInfo();
        await this.$store.dispatch(FETCH_FORM_DATA, { type: formType });
    },
    methods: {
        async saveForm(payload) {
            try {
                console.log("payload saveForm :>> ", payload);
                // await this.saveSection(payload);
                await axios.post("/case/additional-forms/submit");
                this.isSubmitted = true;
            } catch (error) {
                console.log(error, "[+]error while submitting the form");
            }
        },
        formatSections(data) {
            return { sections: [data.data] };
        },
        async saveSection(payload) {
            let sectionSaved = false;
            // adding the condtion for end-point in addtional form (refree or candidate);
            let formType = this.getFormType === "case_references" ? "referee" : this.getFormType;
            
            const url = `/case/additional-forms/${formType}`;
            try {
                await axios.post(url, this.formatSections(payload));
                sectionSaved = true;
            } catch (error) {
                console.log(error, "[+] error in save form section");
                // const { error: resp } = error?.response?.data?.detail;
                sectionSaved = false;
            }
            return sectionSaved;
        },
        async fetchUserInfo() {
            try {
                const { data } = await axios.get("/case/additional-forms/details");
                this.additionalLogo = data?.client?.logo_url ?? null;
                this.additionalIcon = data?.tenant?.icon ?? null;
                if (data.case_id) {
                    this.$router.replace({ name: "form-delvium-additional-form", query: { case_id: data.case_id } });
                }
            } catch (error) {
                console.log(error, "[+] error while fetching user info");
            }
        },
        getUserName(str) {
            return str
            .toLowerCase()
            .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
        }
    },
};
</script>

<style lang="scss" scoped>
    @media (max-width: 639px){
    .formSubmitModal{
        min-height: 67% !important
    }
    }
</style>
